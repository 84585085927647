import FreeText from '@algbra/ui/components/FreeText/FreeText'
import { Column, Container, Row } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Icon from '@algbra/ui/components/Icon/Icon'
import Permalink from '@algbra/ui/components/Permalink/Permalink'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { getIconByName } from '@algbra/ui/icons'
import { resolveAsImage } from '@algbra/ui/resolvers'
import { nl2br } from '@algbra/ui/text'
import { GatsbyResolvedAsset } from '@algbra/ui/types/image'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { map, mapIndexed } from 'rambdax'
import { useRef, useState } from 'react'
import styles from './EventPresentationBoxList.module.scss'

export type PresentationBoxProps = {
  id: string
  title: string
  size: 'full' | 'half'
  presenter_name: string
  presenter_title?: string
  image: GatsbyResolvedAsset
  youtube_code: string
}

export type ZoneEventPresentationBoxListProps = {
  __typename: 'StrapiGQL_ComponentZonesPresentationBoxList'
  id: string
  title: string
  presentations: PresentationBoxProps[]
}

export function PresentationBox({
  title,
  image,
  presenter_name,
  presenter_title,
  youtube_code,
}: PresentationBoxProps) {
  const [isDialogVisible, setDialogVisible] = useState(false)
  const onToggleVideo = () => {
    setDialogVisible(!isDialogVisible)
  }
  return (
    <>
      <div className={styles.box} onClick={onToggleVideo}>
        <Row>
          <Column size={12} sm={6} className={styles.contentCol}>
            <div className={styles.content}>
              <Heading level={2} type="md" className={styles.title}>
                <span dangerouslySetInnerHTML={{ __html: nl2br(title) }}></span>
              </Heading>
              <div>
                <Heading type="sm" className={styles.presenterName}>
                  {presenter_name}
                </Heading>
                {presenter_title && (
                  <FreeText type="md" className={styles.presenterTitle}>
                    {presenter_title}
                  </FreeText>
                )}
              </div>
            </div>
          </Column>
          <Column size={12} sm={6} className={styles.imageCol}>
            <div className={styles.image}>
              <GatsbyImage image={resolveAsImage(image)} alt={presenter_name} />
            </div>
          </Column>
        </Row>
        <span className={styles.playButton}>
          <StaticImage
            src="../../images/events/presentation-play.png"
            alt={`Play video: ${title}`}
            layout="constrained"
            width={150}
            quality={100}
            placeholder="blurred"
            loading="eager"
          />
        </span>
      </div>
      {isDialogVisible && (
        <div className={styles.videoFixed}>
          <div className={styles.videoCloseBar}>
            <Icon
              icon={getIconByName('times')}
              className={styles.closeIcon}
              onClick={onToggleVideo}
            />
          </div>
          <iframe
            title={title}
            style={{ border: 0 }}
            width="100%"
            height="100%"
            allow="autoplay; encrypted-media"
            src={`https://www.youtube.com/embed/${youtube_code}?&autoplay=1&rel=0`}
            allowFullScreen
          ></iframe>
        </div>
      )}
    </>
  )
}

export function ZoneEventPresentationBoxList(
  props: ZoneEventPresentationBoxListProps
): JSX.Element {
  const { title, presentations } = props
  return (
    <Whitespace type="md">
      <Container className={styles.container}>
        <Row>
          {mapIndexed((props, index) => {
            const columnSize = props.size === 'half' ? 6 : 12
            return (
              <Column size={12} sm={columnSize} key={`presentation-${index}`}>
                <PresentationBox {...props} />
              </Column>
            )
          }, presentations)}
        </Row>
      </Container>
    </Whitespace>
  )
}
