import classNames from 'classnames'
import React from 'react'
import styles from './Paragraph.module.scss'

export type ParagraphProps = {
  content: string
  type?: 'xl' | 'lg' | 'md' | 'sm'
  as?: keyof HTMLElementTagNameMap
  className?: string
}

export default function Paragraph(props: ParagraphProps) {
  const { as = 'p', className, type, content } = props
  const elmProps = {
    className: classNames([styles[`type__${type}`]], styles.text, className),
    dangerouslySetInnerHTML: {
      __html: content,
    },
  }
  return React.createElement(as, elmProps)
}
