import Button from '@algbra/ui/components/Button/Button'
import { Container } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import TeamMember, {
  TeamMemberProps
} from '@algbra/ui/components/TeamMember/TeamMember'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { getIconByName } from '@algbra/ui/icons'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { map } from 'rambdax'
import styles from './EventTeam.module.scss'

export type ZoneEventTeamProps = {
  __typename: 'StrapiGQL_ComponentZonesTeamMinimal'
  id: string
  title: string
}

const EVENT_TEAMS_QUERY = graphql`
  query EventTeamQuery {
    strapiGQL {
      teams(sort: "Order", publicationState: LIVE) {
        ...StrapiGQL_TeamFragment
      }
    }
  }
`

export default function ZoneEventTeam(props: ZoneEventTeamProps) {
  const { title } = props
  const {
    strapiGQL: { teams },
  } = useStaticQuery(EVENT_TEAMS_QUERY)

  return (
    <Container fluid={false}>
      <Whitespace type="md">
        <Heading type="lg" theme="dark" className={styles.title}>
          {title}
        </Heading>

        <div className={styles.teamList}>
          {map(
            team => (
              <div className={styles.teamItem} key={team.id}>
                <TeamMember
                  className={styles.teamMember}
                  hideInfo={true}
                  {...(team as TeamMemberProps)}
                />
              </div>
            ),
            teams as TeamMemberProps[]
          )}
        </div>

        <div className={styles.meet}>
          <Button
            onClick={() => navigate('/team')}
            icon={getIconByName('arrow-right')}
            theme="blackTransparent"
            size="normal"
          >
            Meet the team
          </Button>
        </div>
      </Whitespace>
    </Container>
  )
}
