import { Column, Container, Row } from '@algbra/ui/components/Grid/Grid'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { title } from 'process'
import { map, mapIndexed } from 'rambdax'
import { Video } from '../../components/Video/Video'
import styles from './EventVideoList.module.scss'

export type MediaVideo = {
  id: string
  title: string
  cover: string
  youtube_code: string
}
export type ZoneEventVideoListProps = {
  __typename: 'StrapiGQL_ComponentZonesVideoCoverList'
  title: string
  videos: MediaVideo[]
}

export function ZoneEventVideoList({
  title,
  videos,
}: ZoneEventVideoListProps): JSX.Element {
  return (
    <Container className={styles.container}>
      <Whitespace type="md">
        <Row>
          {mapIndexed(
            ({ cover, youtube_code, title }) => (
              <Column size={12} sm={6} key={`video-${youtube_code}`}>
                <Video
                  cover={cover}
                  youtube_code={youtube_code}
                  title={title}
                  isTitleVisible={true}
                />
              </Column>
            ),
            videos
          )}
        </Row>
      </Whitespace>
    </Container>
  )
}
