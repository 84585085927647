import FreeText from '@algbra/ui/components/FreeText/FreeText'
import { Container } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Icon from '@algbra/ui/components/Icon/Icon'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { getIconByName } from '@algbra/ui/icons'
import classNames from 'classnames'
import { map, mapIndexed } from 'rambdax'
import { useState } from 'react'
import styles from './EventFAQ.module.scss'

export type FAQ = {
  id: string
  question: string
  answer: string
  isDefaultOpen?: boolean
}

export type ZoneEventFAQProps = {
  __typename: 'StrapiGQL_ComponentZonesFaq'
  id: string
  title: string
  items: FAQ[]
}

export function EventFAQItem({
  id,
  question,
  answer,
  isDefaultOpen = false,
}: FAQ): JSX.Element {
  const [isOpen, setOpen] = useState<boolean>(isDefaultOpen)
  const iconName = isOpen ? 'arrow-up' : 'arrow-down'
  return (
    <article className={classNames(styles.item, { [styles.active]: isOpen })}>
      <header className={styles.header} onClick={() => setOpen(!isOpen)}>
        <Heading level={3} type="sm" theme="dark" className={styles.question}>
          Q. {question}
        </Heading>
        <Icon
          icon={getIconByName(iconName)}
          className={styles.icon}
          size={24}
        />
      </header>
      <div style={{ display: isOpen ? 'block' : 'none' }}>
        <FreeText className={styles.answer} color="white" innerHTML={answer}>
          {answer}
        </FreeText>
      </div>
    </article>
  )
}
export default function ZoneEventFAQ(props: ZoneEventFAQProps): JSX.Element {
  const { title, items } = props
  return (
    <Container fluid={false}>
      <Whitespace type="md">
        <Heading type="lg" theme="dark" className={styles.title}>
          {title}
        </Heading>
        <div className={styles.list}>
          {mapIndexed(
            (faq: FAQ, index) => (
              <EventFAQItem {...faq} key={faq.id} isDefaultOpen={index === 0} />
            ),
            items
          )}
        </div>
      </Whitespace>
    </Container>
  )
}
