import { mapIndexed } from 'rambdax'
import ZoneEventAgenda, {
  ZoneEventAgendaProps,
} from './EventAgenda/EventAgenda'
import {
  ZoneEventCarousel,
  ZoneEventCarouselProps,
} from './EventCarousel/EventCarousel'
import ZoneEventFAQ, { ZoneEventFAQProps } from './EventFAQ/EventFAQ'
import ZoneEventFreeText, {
  ZoneEventFreeTextProps,
} from './EventFreeText/EventFreeText'
import {
  ZoneEventPresentationBoxList,
  ZoneEventPresentationBoxListProps,
} from './EventPresentationBoxList/EventPresentationBoxList'
import ZoneEventTeam, { ZoneEventTeamProps } from './EventTeam/EventTeam'
import ZoneEventVideo, { ZoneEventVideoProps } from './EventVideo/EventVideo'
import {
  ZoneEventVideoList,
  ZoneEventVideoListProps,
} from './EventVideoList/EventVideoList'

export type ZoneItemType =
  | ZoneEventVideoProps
  | ZoneEventVideoListProps
  | ZoneEventFreeTextProps
  | ZoneEventAgendaProps
  | ZoneEventTeamProps
  | ZoneEventFAQProps
  | ZoneEventPresentationBoxListProps
  | ZoneEventCarouselProps

export type CareersZoneRendererProps = {
  zones: ZoneItemType[]
  date: string
  venue: string
}

export function getComponentByZoneType(
  zone: ZoneItemType,
  key: string,
  { date, venue }
) {
  switch (zone.__typename) {
    case 'StrapiGQL_ComponentZonesVideoCover':
      return <ZoneEventVideo key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesVideoCoverList':
      return <ZoneEventVideoList key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesFreeText':
      return <ZoneEventFreeText key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesAgenda':
      return <ZoneEventAgenda key={key} {...zone} date={date} venue={venue} />
    case 'StrapiGQL_ComponentZonesTeamMinimal':
      return <ZoneEventTeam key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesFaq':
      return <ZoneEventFAQ key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesPresentationBoxList':
      return <ZoneEventPresentationBoxList key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesEventCarousel':
      return <ZoneEventCarousel key={key} {...zone} />
    default:
      return null
  }
}
export default function DecodedZoneRenderer({
  zones,
  date,
  venue,
}: CareersZoneRendererProps) {
  return (
    <main>
      {mapIndexed(
        (zone, index) =>
          getComponentByZoneType(zone, `${zone.__typename}-${index}`, {
            date,
            venue,
          }),
        zones
      )}
    </main>
  )
}
