import FreeText from '@algbra/ui/components/FreeText/FreeText'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import ContentBox from '../../components/ContentBox/ContentBox'
import styles from './EventFreeText.module.scss'

export type ZoneEventFreeTextProps = {
  __typename: 'StrapiGQL_ComponentZonesFreeText'
  id: string
  title: string
  body: string
  color?: 'primary' | 'secondary' | 'tetriary' | 'white'
}

export default function ZoneEventFreeText({
  body,
  color = 'white',
}: ZoneEventFreeTextProps) {
  return (
    <Whitespace type="md" className={styles.container}>
      <ContentBox size="small">
        <FreeText
          color={color}
          type="lg"
          innerHTML={body}
          className={styles.content}
        >
          {body}
        </FreeText>
      </ContentBox>
    </Whitespace>
  )
}
