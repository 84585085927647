import FreeText from '@algbra/ui/components/FreeText/FreeText'
import { Container } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import classNames from 'classnames'
import { graphql } from 'gatsby'
import React, { useEffect, useRef } from 'react'
import MetaTags from '../components/MetaTags/MetaTags'
import PageLayout from '../components/PageLayout/PageLayout'
import { getMetaTags } from '../utils/metaTags'
import DecodedZoneRenderer from '../zones/DecodedZoneRenderer'
import { EventDecodedArea } from '../zones/EventDecodedArea/EventDecodedArea'
import styles from './decoded.module.scss'

export default function LayoutDecoded({ pageContext, data, location }) {
  const decoded = data.strapiGQL.decoded
  const pageRef = useRef<HTMLDivElement>(null)
  const { contentZones, date, venue } = decoded
  useEffect(() => {
    const scrollListener = e => {
      const top = window.pageYOffset
      if (pageRef.current) {
        pageRef.current.style.backgroundPositionY = `-${top * 0.25}px`
      }
    }
    document.addEventListener('scroll', scrollListener)
    return () => {
      document.removeEventListener('scroll', scrollListener)
    }
  }, [pageRef])
  return (
    <PageLayout ref={pageRef} theme="darkTranslucent">
      <MetaTags {...getMetaTags(pageContext)} />
      <Container>
        <Whitespace type="xl" hasAnimation={false}>
          <header className={styles.header}>
            <Heading level={2} theme="dark" type="md">
              {pageContext.siteName}
            </Heading>
            <Heading
              level={1}
              type="xxl"
              theme="dark"
              className={classNames(styles.title)}
            >
              {pageContext.title}
            </Heading>
            {pageContext.subtitle && (
              <FreeText
                type="md"
                className={classNames(styles.colored, styles.subtitle)}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: pageContext.subtitle,
                  }}
                />
              </FreeText>
            )}
          </header>
        </Whitespace>
      </Container>
      {contentZones && (
        <DecodedZoneRenderer zones={contentZones} date={date} venue={venue} />
      )}
      <EventDecodedArea />
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query DecodedPage {
    strapiGQL {
      decoded(publicationState: LIVE) {
        id
        date
        venue
        contentZones {
          __typename
          ... on StrapiGQL_ComponentZonesVideoCoverList {
            id
            title
            videos {
              id
              title
              cover
              youtube_code
            }
          }
          ... on StrapiGQL_ComponentZonesVideoCover {
            id
            title
            cover
            youtube_code
          }
          ... on StrapiGQL_ComponentZonesTeamMinimal {
            id
            title
          }
          ... on StrapiGQL_ComponentZonesAgenda {
            id
            title
          }
          ... on StrapiGQL_ComponentZonesFaq {
            id
            title
            items {
              answer
              id
              question
            }
          }
          ... on StrapiGQL_ComponentZonesFreeText {
            id
            body
          }
          ... on StrapiGQL_ComponentZonesEventCarousel {
            id
            title
            images {
              id
              alternativeText
              video {
                id
                url
                ext
                resolved {
                  publicURL
                }
              }
              fullWidth: image {
                id
                url
                ext
                resolved {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      layout: FULL_WIDTH
                      formats: WEBP
                      webpOptions: { quality: 100 }
                    )
                  }
                }
              }
              image {
                id
                url
                ext
                resolved {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      layout: CONSTRAINED
                      formats: WEBP
                      webpOptions: { quality: 100 }
                      height: 530
                    )
                  }
                }
              }
            }
          }
          ... on StrapiGQL_ComponentZonesPresentationBoxList {
            id
            title
            presentations {
              id
              title
              size
              presenter_name
              presenter_title
              youtube_code
              image {
                id
                url
                ext
                resolved {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      formats: WEBP
                      webpOptions: { quality: 100 }
                      height: 320
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
