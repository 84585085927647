import Heading from '@algbra/ui/components/Heading/Heading'
import Icon from '@algbra/ui/components/Icon/Icon'
import { getIconByName } from '@algbra/ui/icons'
import { useRef, useState } from 'react'
import styles from './Video.module.scss'
import { VideoFullScreen } from './VideoFullscreen'

export type VideoProps = {
  cover: string
  youtube_code: string
  title?: string
  isTitleVisible?: boolean
}
export function Video({
  cover,
  youtube_code,
  title,
  isTitleVisible,
}: VideoProps) {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isFullscreen, setFullscreen] = useState(false)
  const onToggleVideo = () => {
    setFullscreen(!isFullscreen)
  }
  return (
    <>
      <div className={styles.videoContainer}>
        <video
          ref={videoRef}
          src={cover}
          className={styles.video}
          onClick={onToggleVideo}
          playsInline={true}
          autoPlay={true}
          loop={true}
          muted={true}
        />
        <img
          src="/images/video-play.svg"
          width={62}
          alt=""
          className={styles.videoPlay}
        />
        {isTitleVisible && (
          <Heading type="sm" theme="dark" className={styles.title}>
            {title}
          </Heading>
        )}
      </div>
      {isFullscreen && (
        <VideoFullScreen
          onToggleVideo={onToggleVideo}
          youtube_code={youtube_code}
        />
      )}
    </>
  )
}
