import Caption from '@algbra/ui/components/Caption/Caption'
import Dialog from '@algbra/ui/components/Dialog/Dialog'
import FreeText from '@algbra/ui/components/FreeText/FreeText'
import { Column, Container, Row } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Paragraph from '@algbra/ui/components/Paragraph/Paragraph'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import useClickOutside from '@algbra/ui/hooks/useClickOutside'
import { resolveAsImage } from '@algbra/ui/resolvers'
import { GatsbyResolvedAsset } from '@algbra/ui/types/image'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getSrc } from 'gatsby-plugin-image'
import { map } from 'rambdax'
import { useRef, useState } from 'react'
import styles from './EventAgenda.module.scss'

export type ZoneEventAgendaProps = {
  __typename: 'StrapiGQL_ComponentZonesAgenda'
  id: string
  title: string
  date: string
  venue: string
}

const AGENDA_LIST = graphql`
  query DecodedAgenda {
    lightOne: file(relativePath: { eq: "decoded-dialog-light-left.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 570
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP]
        )
      }
    }
    lightTwo: file(relativePath: { eq: "decoded-dialog-light-right.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 570
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP]
        )
      }
    }
    strapiGQL {
      decodedAgendum(publicationState: LIVE) {
        items {
          id
          time
          presentation {
            topic
            id
            speakers {
              id
              fullName
              title
              bio
              picture {
                id
                url
                ext
                resolved {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      layout: CONSTRAINED
                      formats: WEBP
                      width: 180
                      webpOptions: { quality: 100 }
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export type AgendaPresentationSpeaker = {
  fullName: string
  title?: string
  picture: GatsbyResolvedAsset
  bio?: string
}
export type AgendaPresentation = {
  id: string
  topic: string
  speakers: AgendaPresentationSpeaker[]
}
export type AgendaItem = {
  id: string
  time: string
  presentation: AgendaPresentation[]
}

export const EventSpeaker = (props: AgendaPresentationSpeaker) => {
  const { lightOne, lightTwo } = useStaticQuery(AGENDA_LIST)
  const [isDialogVisible, setDialogVisibility] = useState<boolean>()
  const { fullName, title, picture, bio } = props
  const dialogRef = useRef<HTMLDivElement>(null)
  useClickOutside(dialogRef, () => {
    setDialogVisibility(false)
  })
  return (
    <>
      <span
        className={styles.speaker}
        onClick={() => setDialogVisibility(true)}
      >
        <GatsbyImage
          image={resolveAsImage(picture)}
          alt={`Picture of ${fullName}`}
          className={styles.picture}
        />
        <span className={styles.fullName}>{fullName}</span>
      </span>
      {isDialogVisible && (
        <Dialog
          isOpen={isDialogVisible}
          className={styles.eventDialog}
          hasGradient={false}
          theme="dark"
          onClose={() => {
            setDialogVisibility(false)
            return false
          }}
        >
          <div className={styles.dialogContent} ref={dialogRef}>
            <div className={styles.dialogContentInner}>
              {picture && (
                <header>
                  <GatsbyImage
                    image={resolveAsImage(picture)}
                    alt={`Picture of ${fullName}`}
                    className={styles.dialogImage}
                  />
                  <Heading level={2} type="md" theme="dark">
                    {fullName}
                  </Heading>
                  {title && (
                    <Caption type="md" className={styles.title}>
                      {title}
                    </Caption>
                  )}
                </header>
              )}
              <FreeText
                type="md"
                innerHTML={bio}
                color="white"
                className={styles.bio}
              >
                {bio}
              </FreeText>
            </div>
          </div>
          <div
            className={classNames(styles.light, styles.lightOne)}
            style={{ backgroundImage: `url(${getSrc(lightOne)})` }}
          ></div>
          <div
            className={classNames(styles.light, styles.lightTwo)}
            style={{ backgroundImage: `url(${getSrc(lightTwo)})` }}
          ></div>
        </Dialog>
      )}
    </>
  )
}

export default function ZoneEventAgenda({
  title,
  date,
  venue,
}: ZoneEventAgendaProps) {
  const {
    strapiGQL: { decodedAgendum },
  } = useStaticQuery(AGENDA_LIST)
  const items: AgendaItem[] = decodedAgendum.items

  return (
    <Whitespace type="md">
      <Container className={styles.container}>
        <Row className={styles.header}>
          <Column size={12}>
            <Heading theme="dark" type="lg">
              {title}
            </Heading>
          </Column>
        </Row>

        {map(
          ({ time, presentation }) => (
            <Row className={styles.agendaItem} key={time}>
              <Column className={styles.rowKey}>
                <span className={styles.time}>{time}</span>
              </Column>
              <Column className={styles.rowValue}>
                {map(
                  ({ topic, speakers }) => (
                    <div
                      className={classNames(styles.presentation, {
                        [styles.withSpeakers]: speakers.length > 0,
                      })}
                      key={topic}
                    >
                      <div className={styles.topic}>
                        <Paragraph content={topic} />
                      </div>
                      {speakers.length > 0 && (
                        <div className={styles.speakers}>
                          {map(
                            speaker => (
                              <EventSpeaker
                                key={`speaker-${speaker.fullName}`}
                                {...speaker}
                              />
                            ),
                            speakers
                          )}
                        </div>
                      )}
                    </div>
                  ),
                  presentation
                )}
              </Column>
            </Row>
          ),
          items || []
        )}

        <Row className={styles.footer}>
          <Column className={styles.rowKey}>
            <span className={styles.locationLabel}>Location</span>
          </Column>
          <Column className={styles.rowValue}>
            <div className={styles.location}>
              <img
                src="/images/logo-design-museum.svg"
                alt="Design Museum Logo"
                width={160}
                className={styles.locationLogo}
              />
            </div>
          </Column>
        </Row>
      </Container>
    </Whitespace>
  )
}
