import { Container } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import ZoneCarousel, { CarouselImage } from '../Carousel/Carousel'
import styles from './EventCarousel.module.scss'

export type ZoneEventCarouselProps = {
  __typename: 'StrapiGQL_ComponentZonesEventCarousel'
  title: string
  images: CarouselImage[]
}
export function ZoneEventCarousel({
  images,
  title,
}: ZoneEventCarouselProps): JSX.Element {
  return (
    <Whitespace type="md">
      <Container>
        <Heading type="lg" theme="light" className={styles.title}>
          {title}
        </Heading>
      </Container>
      <div className={styles.carousel}>
        <ZoneCarousel
          __typename="StrapiGQL_ComponentZonesCarousel"
          images={images}
          carouselOnly={true}
          hasPopup={true}
        />
      </div>
    </Whitespace>
  )
}
