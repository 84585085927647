import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { graphql, useStaticQuery } from 'gatsby'
import { getSrc, StaticImage } from 'gatsby-plugin-image'
import { useEffect, useRef } from 'react'
import ZoneWaitlistDark from '../WaitlistDark/WaitlistDark'
import styles from './EventDecodedArea.module.scss'

export function EventDecodedArea(): JSX.Element {
  const { bgImage } = useStaticQuery(
    graphql`
      query {
        bgImage: file(relativePath: { eq: "text-decoded.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1070
              placeholder: BLURRED
              layout: CONSTRAINED
              formats: [WEBP]
            )
          }
        }
      }
    `
  )

  return (
    <div className={styles.area}>
      <Whitespace type="md">
        <div className={styles.container}>
          <div className={styles.textWrapper}>
            <div
              className={styles.text}
              style={{ backgroundImage: `url(${getSrc(bgImage)})` }}
            />
          </div>
          <div className={styles.card}>
            <StaticImage
              loading="lazy"
              src="../../images/events/card.png"
              width={430}
              height={476}
              alt="Algbra Card"
            />
          </div>
        </div>
      </Whitespace>
    </div>
  )
}
