import { Container } from '@algbra/ui/components/Grid/Grid'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { Video } from '../../components/Video/Video'
import styles from './EventVideo.module.scss'

export type ZoneEventVideoProps = {
  __typename: 'StrapiGQL_ComponentZonesVideoCover'
  id: string
  title: string
  cover: string
  youtube_code: string
}

export default function ZoneEventVideo({
  cover,
  youtube_code,
  title,
}: ZoneEventVideoProps) {
  return (
    <Container className={styles.container}>
      <Whitespace type="md">
        <Video
          cover={cover}
          youtube_code={youtube_code}
          title={title}
          isTitleVisible={false}
        />
      </Whitespace>
    </Container>
  )
}
